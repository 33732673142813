import {
  Box,
  Container,
  ContainerProps,
  styled,
  SxProps,
  Theme,
} from "@mui/material";
import React from "react";

const Background = styled(Box, {
  shouldForwardProp: (prop) => prop != "sectionBgcolor" && prop != "customBg",
})<{
  sectionBgcolor?: "primary" | "secondary" | "neutral";
  custtomBg?: string;
}>(({ theme, sectionBgcolor, custtomBg }) => ({
  background: custtomBg || theme.palette[sectionBgcolor]?.main,
  paddingTop: "16px",
  paddingBottom: "16px",
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "16px",
    paddingRight: "16px",
  },
}));

type HhContainerProps = {
  sectionBgcolor?: "primary" | "secondary" | "neutral";
  customBg?: string;
  rootSx?: SxProps<Theme>;
} & ContainerProps;

const HhContainer = (props: HhContainerProps) => {
  const { children, maxWidth, sectionBgcolor, rootSx, customBg, ...restProps } =
    props;
  return (
    <Background
      sectionBgcolor={sectionBgcolor}
      component="section"
      sx={rootSx}
      custtomBg={customBg}
    >
      <Container maxWidth={maxWidth || "lg"} {...restProps}>
        {children}
      </Container>
    </Background>
  );
};

export default HhContainer;
