import { Box, Typography, styled } from "@mui/material";
import React from "react";
import { mediaData, SocialMedia } from "../sections/help-card-section";
import { FlexBox } from "./primitives/flex";

const HeadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.neutral.main,
  fontSize: "16px",
  fontWeight: "600",
  paddingBottom: "16px",
}));

const SocialLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  paddingTop: "5px",
  cursor: "pointer",
}));

const FollowUs = () => {
  return (
    <Box>
      <HeadText>Follow Us</HeadText>
      <FlexBox>
        {mediaData.map((med) => (
          <SocialLink href={med.linkUrl} target="_blank">
            <SocialMedia bgColor={med.bgColor}>{med.icon}</SocialMedia>
          </SocialLink>
        ))}
      </FlexBox>
    </Box>
  );
};

export default FollowUs;
