import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Palette {
    neutral: Palette["primary"];
  }
  interface PaletteOptions {
    neutral: PaletteOptions["primary"];
  }
}

const defaultHeadingStyles = {
  fontFamily: `"proxima-soft", sans-serif`,
};

// A custom theme for this app
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1000,
      lg: 1280,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: "#0a1c38",
      light: "#e1f7f8",
      dark: "#061123",
    },
    secondary: {
      main: "#3bc1cc",
      light: "#c5d7f5",
      dark: "#f0483f",
    },
    text: {
      primary: "#000000",
      secondary: "#6d9ce51a",
    },
    neutral: {
      main: "#ffffff",
      dark: "#f1f1f1",
    },
  },
  typography: {
    fontFamily: `"proxima-soft", sans-serif`,
    h1: { ...defaultHeadingStyles },
    h2: { ...defaultHeadingStyles },
    h3: { ...defaultHeadingStyles },
    h4: {
      ...defaultHeadingStyles,
      "@media (max-width:600px)": {
        fontSize: "1.5rem",
      },
    },
    h5: {
      ...defaultHeadingStyles,
      "@media (max-width:600px)": {
        fontSize: "1.375rem",
      },
    },
  },
});

export default theme;
