import { Button, styled, SxProps } from "@mui/material";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import React from "react";

const ArrowIcon = styled(ArrowForwardIosRoundedIcon)(({ theme }) => ({
  color: theme.palette.neutral.main,
  position: "absolute",
  fontSize: "20px",
  top: "50%",
  transform: "translateY(-50%)",
  right: "20px",
}));

const Link = styled("a")(({ theme }) => ({
  color: theme.palette.neutral.main,
  textDecoration: "none",
  fontSize: "20px",
  fontWeight: "600",
  lineHeight: 1,
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.neutral.main,
  padding: "20px 40px",
  justifyContent: "center",
  borderRadius: "40px",
  fontFamily: `"proxima-soft", sans-serif`,
  fontSize: "20px",
  fontWeight: "600",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  position: "relative",
  textTransform: "capitalize",
  "&:hover": {
    backgroundColor: "#19468D",
  },
}));

export const NavButton = styled(Button)(({ theme }) => ({
  background: "tranparent",
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  display: "flex",
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: `"proxima-soft", sans-serif`,
  cursor: "pointer",
  color: theme.palette.secondary.main,
  textTransform: "capitalize",
  alignItems: "baseline",
  transition: "background-color .25s",
  "&:hover": {
    background: theme.palette.text.secondary,
  },
  [theme.breakpoints.down(1100)]: {
    fontSize: "12px",
    padding: "4px 6px",
  },
}));

export const ContactButton = styled(Button, {
  shouldForwardProp: (p) => p != "showInMobile",
})<{ showInMobile?: boolean }>(({ theme, showInMobile }) => ({
  display: showInMobile ? "none" : "block",
  background: theme.palette.secondary.main,
  padding: "4px 16px",
  borderRadius: "20px",
  border: "none",
  fontFamily: `"proxima-soft", sans-serif`,
  fontSize: "14px",
  fontWeight: "600",
  cursor: "pointer",
  color: "#19468d",
  textTransform: "capitalize",
  alignItems: "baseline",
  "&:hover": {
    background: theme.palette.secondary.main,
    opacity: 0.8,
    transition: ".5s",
  },
  [theme.breakpoints.down(1100)]: {
    fontSize: "12px",
    padding: "4px 8px",
  },
  [theme.breakpoints.down("md")]: {
    display: showInMobile ? "block" : "none",
    padding: "4px 16px",
    fontSize: "14px",
  },
}));

interface SubmitButtonProps {
  btnText: string;
  onClick?: () => void;
  type?: "submit" | "reset" | "button";
  href?: string;
  newTab?: boolean;
  sx?: SxProps;
  disabled?: boolean;
}

const SubmitButton = (props: SubmitButtonProps) => {
  const { btnText, onClick, type, href, newTab, sx, disabled } = props;
  return (
    <CustomButton
      onClick={onClick}
      type={type}
      href={href}
      sx={sx}
      disabled={disabled}
    >
      <Link href={href} target={newTab && "_blank"}>
        {btnText}
      </Link>
      <ArrowIcon />
    </CustomButton>
  );
};

export { SubmitButton };
