import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import theme from "../theme";
import { styled, Box, Typography } from "@mui/material";

const DrawerContainer = styled(Box)(({ theme }) => ({
  display: "none",
  [theme.breakpoints.down("md")]: {
    display: "block",
  },
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.secondary.main,
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  justifyContent: "space-between",
  paddingTop: "10px",
  paddingBottom: "30px",
  paddingLeft: "10px",
  paddingRight: "10px",
  borderBottom: `1px solid ${theme.palette.primary.light}`,
}));

const Cancel = styled(CancelIcon)(({ theme }) => ({
  color: theme.palette.neutral.main,
  cursor: "pointer",
  height: "36px",
  width: "36px",
}));

const ListPrint = styled(ListItem)<{ colorExist: boolean }>(
  ({ colorExist }) => ({
    color: "#f1f1f1",
    cursor: "pointer",
    padding: "8px 0px",
    backgroundColor: colorExist ? "#19468D" : "",
    ":hover": {
      color: theme.palette.secondary.main,
    },
  })
);

interface IProps {
  menu: {
    id?: string;
    navigationLink?: { text?: string; url?: string };
    title?: string;
  }[];
  activeTab: string;
  handleTab: (tab: {
    id?: string;
    navigationLink?: {
      text?: string;
      url?: string;
    };
    title?: string;
  }) => void;
}

const DrawerMenu = ({ menu, activeTab, handleTab }: IProps) => {
  const [state, setState] = React.useState(false);

  const toggleDrawer = (data: boolean) => {
    setState(data);
  };

  console.log("Nav Link", activeTab);

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onKeyDown={() => toggleDrawer(false)}
    >
      <DrawerHeader>
        <Logo>hellohaus</Logo>
        <Cancel onClick={() => toggleDrawer(false)} />
      </DrawerHeader>
      <List>
        {menu.map((nav, index) => {
          console.log("url is ", nav.navigationLink.url);
          return (
            <ListPrint
              key={nav.navigationLink.url}
              disablePadding
              colorExist={activeTab === `${nav.navigationLink.url}/`}
              onClick={() => handleTab(nav)}
            >
              <ListItemButton href={activeTab}>
                <ListItemText primary={nav.title} />
              </ListItemButton>
            </ListPrint>
          );
        })}
      </List>
    </Box>
  );

  return (
    <DrawerContainer>
      <Button onClick={() => toggleDrawer(true)}>
        <MenuIcon sx={{ color: "#3bc1cc" }} />
      </Button>
      <Drawer
        PaperProps={{ sx: { backgroundColor: "#141634" } }}
        anchor="left"
        onClose={() => toggleDrawer(false)}
        open={state}
        variant="persistent"
      >
        {list()}
      </Drawer>
    </DrawerContainer>
  );
};

export default DrawerMenu;
