import Navbar from "./navbar";
import React from "react";
import { Box } from "@mui/material";
import Footer from "./footer";
import "../../style.css";
// import CrossSiteNavbar from "./cross-site-navbar";

interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  return (
    <React.Fragment>
      <Box sx={{ overflowX: "clip" }}>
        {/* <CrossSiteNavbar /> */}
        <Navbar />
        <main>{props.children}</main>
        <Footer />
      </Box>
    </React.Fragment>
  );
};

export default Layout;
