import { styled } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { NavigationQueryQuery } from "../../../graphql-types";
import DrawerMenu from "../custom-drawer";
import LogoBox from "../logo-box";
import { ContactButton, NavButton } from "../primitives/button";
import { FlexBox } from "../primitives/flex";
import NavSearchInput from "../inputs/nav-search-input";

const menu = [
  { name: "Home", link: "/" },
  { name: "How We Work", link: "how-we-work" },
  { name: "Portfolio Builder", link: "portfolio-builder" },
  { name: "Agent Selector", link: "agent-selector" },
  { name: "Get Buyer Ready", link: "get-buyer-ready" },
  { name: "About", link: "about" },
  { name: "Review", link: "review" },
  { name: "Blog", link: "blog" },
];

const LinkBox = styled(FlexBox)(({ theme }) => ({
  alignItems: "center",
  width: "100%",
  maxWidth: "960px",
  position: "relative",
  justifyContent: "space-between",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const NavbarBox = styled("nav")(({ theme }) => ({
  display: "flex",
  width: "100%",
  position: "sticky",
  top: 0,
  zIndex: "4",
  background: theme.palette.primary.main,
  justifyContent: "center",
  opacity: 0.98,
}));

const ExternalLink = styled("a")(({ theme }) => ({
  background: "tranparent",
  padding: "8px 16px",
  borderRadius: "20px",
  border: "none",
  display: "flex",
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: `"proxima-soft", sans-serif`,
  cursor: "pointer",
  color: theme.palette.secondary.main,
  textTransform: "capitalize",
  alignItems: "baseline",
  transition: "background-color .25s",
  textDecoration: "none",
  "&:hover": {
    background: theme.palette.text.secondary,
  },
  [theme.breakpoints.down(1100)]: {
    fontSize: "12px",
    padding: "4px 6px",
  },
}));

const ElementBox = styled(FlexBox)(({ theme }) => ({
  width: "100%",
  maxWidth: "1400px",
  padding: "10px 20px",
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    padding: "10px 16px 10px 0px",
  },
}));

const Navbar = () => {
  const navMenu = useStaticQuery<NavigationQueryQuery>(graphql`
    query NavigationQuery {
      CraftGQL {
        helloHausNavigationEntries(site: "helloHaus") {
          ... on CraftGQL_helloHausNavigation_default_Entry {
            id
            title
            isexternallink
            navigationLink {
              text
              url
            }
          }
        }
      }
    }
  `);

  const {
    CraftGQL: { helloHausNavigationEntries: navigationEntries },
  } = navMenu;

  const windows = typeof window !== "undefined";
  const [activeTab, setActiveTab] = React.useState(
    windows && window.location.pathname
  );
  const handleTab = (tab: {
    id?: string;
    navigationLink?: { text?: string; url?: string };
    title?: string;
  }) => {
    setActiveTab(tab.navigationLink.url);
  };
  return (
    <NavbarBox>
      <ElementBox>
        <DrawerMenu
          menu={navigationEntries}
          activeTab={activeTab}
          handleTab={handleTab}
        />
        <LogoBox showHomeText />
        <LinkBox>
          {navigationEntries.map((nav) => {
            return nav.isexternallink.length ? (
              <ExternalLink href={nav.navigationLink.url} target="_blank">
                {nav.title}
              </ExternalLink>
            ) : (
              <NavButton href={nav.navigationLink.url}>{nav.title}</NavButton>
            );
          })}
          <ContactButton href="/get-help-now" sx={{ padding: "8px 16px" }}>
            Get Help Now
          </ContactButton>
          <NavSearchInput />
        </LinkBox>
        <ContactButton showInMobile>Call</ContactButton>
      </ElementBox>
    </NavbarBox>
  );
};

export default Navbar;
