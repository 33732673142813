import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material";
import * as React from "react";

function HHLogo(props: SvgIconProps) {
  const { sx, ...restProps } = props;

  return (
    <SvgIcon
      {...restProps}
      version="1.1"
      className="hh_logo"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 270 70"
      style={{
        height: "60px",
        width: "150px",
        paddingTop: "6px",
      }}
    >
      <g className="hh_name">
        <path
          className="hh_haus st4"
          fill="#ffff"
          d="M152.99,11.93c0-1.25,0.97-2.28,2.28-2.28c1.25,0,2.28,1.03,2.28,2.28V23.9c1.82-1.94,4.73-3.54,8.49-3.54c6.67,0,11.97,4.91,11.97,13.17v14.59c0,1.31-1.03,2.34-2.28,2.34c-1.31,0-2.28-1.03-2.28-2.34 V33.42c0-5.87-3.54-8.84-7.98-8.84c-4.33,0-7.92,2.91-7.92,8.84v14.71c0,1.31-1.03,2.34-2.28,2.34c-1.31,0-2.28-1.03-2.28-2.34 V11.93z M209.51,46.02c-2.68,2.85-6.38,4.56-10.66,4.56c-8.38,0-14.77-6.67-14.77-15.11s6.38-15.11,14.77-15.11 c4.28,0,7.98,1.77,10.66,4.56v-2.17c0-1.25,1.03-2.28,2.28-2.28c1.31,0,2.28,1.02,2.28,2.28v25.37c0,1.31-0.97,2.34-2.28,2.34 c-1.26,0-2.28-1.03-2.28-2.34V46.02z M209.51,35.93v-0.91c-0.23-5.93-4.73-10.43-10.38-10.43c-5.81,0-10.43,4.73-10.43,10.89 s4.62,10.89,10.43,10.89C204.77,46.36,209.28,41.85,209.51,35.93z M220.73,22.75c0-1.25,0.97-2.28,2.28-2.28 c1.25,0,2.28,1.02,2.28,2.28v14.77c0,5.82,3.53,8.84,7.92,8.84c4.45,0,7.98-3.02,7.98-8.84V22.75c0-1.25,0.97-2.28,2.28-2.28 c1.25,0,2.28,1.02,2.28,2.28v14.59c0,8.27-5.53,13.23-12.54,13.23c-6.96,0-12.48-4.96-12.48-13.23V22.75z M253.45,41.45 c0.74,0,1.65,0.46,1.94,1.37c0.51,1.6,1.99,3.65,5.07,3.65c2.74,0,4.5-1.99,4.5-4.16c0-2.85-2.28-4.16-5.3-5.19 c-3.93-1.31-7.92-2.85-7.92-8.49c0-4.68,3.71-8.27,8.78-8.27c4.79,0,8.84,2.96,8.84,6.84c0,1.14-0.91,2.05-2.05,2.05 c-0.63,0-1.65-0.29-2-1.6c-0.4-1.48-2.11-3.25-4.79-3.25c-2.79,0-4.39,1.99-4.39,4.22c0,3.02,2.74,4.05,6.1,5.13 c3.59,1.08,7.3,3.08,7.3,8.44c0,4.5-3.88,8.38-9.06,8.38c-4.96,0-9.06-3.36-9.06-7.07C251.4,42.37,252.31,41.45,253.45,41.45z"
        ></path>
        <path
          className="hh_ello st3"
          fill="#3bc1cc"
          d="M55.27,35.47c0-8.44,6.39-15.11,15.22-15.11c4.67,0,8.21,1.94,9.8,3.42 c0.91,0.8,1.43,1.94,1.43,3.08c0,0.97-0.29,1.82-1.08,2.79l-7.35,8.78c-0.8,0.91-1.65,1.31-2.74,1.31c-2.17,0-3.82-1.54-3.82-3.65 c0-0.86,0.23-1.71,0.86-2.39l4.9-5.64c-0.4-0.17-1.37-0.29-1.99-0.29c-4.05,0-7.18,3.42-7.18,7.7c0,4.27,3.08,7.7,7.18,7.7 c2.11,0,3.53-0.86,4.5-1.65c1.14-0.97,1.94-1.82,3.48-1.82c2.22,0,3.93,1.59,3.93,3.82c0,0.8-0.4,1.77-1.14,2.62 c-1.94,2.34-5.87,4.45-10.77,4.45C61.72,50.58,55.27,43.96,55.27,35.47z M87.94,13.52c0-2.22,1.77-3.99,3.99-3.99 c2.22,0,3.99,1.77,3.99,3.99v33.07c0,2.22-1.77,3.99-3.99,3.99c-2.22,0-3.99-1.77-3.99-3.99V13.52z M103.03,13.52 c0-2.22,1.77-3.99,3.99-3.99c2.22,0,3.99,1.77,3.99,3.99v33.07c0,2.22-1.77,3.99-3.99,3.99c-2.22,0-3.99-1.77-3.99-3.99V13.52z M116.63,35.47c0-8.44,6.38-15.11,15.22-15.11c8.84,0,15.22,6.67,15.22,15.11s-6.38,15.11-15.22,15.11 C123.01,50.58,116.63,43.91,116.63,35.47z M138.97,35.47c0-4.16-2.96-7.7-7.12-7.7c-4.16,0-7.18,3.53-7.18,7.7 c0,4.16,3.02,7.7,7.18,7.7C136.01,43.17,138.97,39.63,138.97,35.47z"
        ></path>
      </g>
      <g id="hh_brandmark">
        <path
          className="hh_bottomstroke st1"
          fill="#3bc1cc"
          d="M30,10.5v10.36C24.18,22.91,20,28.47,20,35V15C22.91,12.81,26.31,11.25,30,10.5z"
        ></path>
        <path
          className="hh_topstroke st2"
          opacity={0.7}
          fill="#3bc1cc"
          d="M30,0.35V10.5c-3.69,0.75-7.09,2.31-10,4.5V3.37C23.11,1.89,26.47,0.86,30,0.35z"
        ></path>
        <path
          className="hh_arch st3"
          fill="#3bc1cc"
          d="M50,35v15H40V35c0-2.76-2.24-5-5-5s-5,2.24-5,5v15H20V35c0-8.28,6.72-15,15-15S50,26.72,50,35z"
        ></path>
        <path
          className="hh_door st4"
          fill="#ffff"
          d="M40,35v15H30V35c0-2.76,2.24-5,5-5 S40,32.24,40,35z"
        ></path>
      </g>
    </SvgIcon>
  );
}

export default HHLogo;
