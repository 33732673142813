/* eslint-disable import/prefer-default-export */
import React from 'react';
import { CacheProvider } from '@emotion/react';
import getEmotionCache from './getEmotionCache';

const cache = getEmotionCache();

export const wrapRootElement = ({ element }) => {
  return (
    <CacheProvider value={cache}>
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="../../src/assets/favicon/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="../../src/assets/favicon/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="../../src/assets/favicon/favicon-16x16.png"
      />
      <link rel="manifest" href="../../src/assets/favicon/site.webmanifest" />
      <link rel="mask-icon" href="../../src/assets/favicon/safari-pinned-tab.svg" />
      {element}
    </CacheProvider>
  );
};
