import firebase from "gatsby-plugin-firebase";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

// IMPORTANT!!! REPLACE WITH ENV VARIABLES
const RECAPTCHA_SITE_KEY = "6LfolLkdAAAAAJRvtvfbZGP5-R5fi_tGsx4QhDrf";
export const reCaptchaScriptURL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;

export const verifyCaptcha = async (token: string) => {
  try {
    let verify = firebase
      .app()
      .functions("australia-southeast1")
      .httpsCallable("httpsCallableCaptchaVerify");
    let response = await verify({ token });
    return JSON.parse(response.data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const onReadyCaptcha = (
  onSuccess: () => void,
  onError: (error: { error: string }) => void
) => {
  window.grecaptcha.ready(async function () {
    window.grecaptcha
      .execute(RECAPTCHA_SITE_KEY, { action: "submit" })
      .then(async function (token: string) {
        let response = await verifyCaptcha(token);

        if (response?.success) {
          onSuccess();
        } else {
          onError({ error: "Captcha Verification Failed" });
          console.log({ response });
        }
      });
  });
};
