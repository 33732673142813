import firebase from "gatsby-plugin-firebase";

export enum ZapierDataType {
  helloHausContactForm = "helloHausContactForm",
}

export const sendZapierData = async (
  dataType: ZapierDataType,
  zapierData: any
) => {
  try {
    let myRequest = firebase
      .app()
      .functions("australia-southeast1")
      .httpsCallable("httpsSendDataToZapier");

    let response = await myRequest({ dataType, data: zapierData });
    return response.data;
  } catch (error) {
    console.log(error);
    return error;
  }
};
