import { styled } from "@mui/material";

const TextInput = styled("input")(({ theme }) => ({
  width: "100%",
  padding: "8px 10px",
  outline: "none",
  borderRadius: "4px",
  fontSize: "16px",
  fontWeight: "500",
  border: "1px solid #cacaca",
  boxShadow: "inset 0 1px 2px rgba(10,10,10,.1), 0 0 0 0 rgba(59,193,204,0)",
  ":focus": {
    border: "1px solid #3bc1cc",
    boxShadow: "inset 0 0 0 rgba(10,10,10,0), 0 0 0 4px rgba(59,193,204,.5)",
    transition: ".5s all",
  },
}));

export { TextInput };
