import { styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { navigate } from "gatsby";

const CloseIcon = styled(CloseRoundedIcon, {
  shouldForwardProp: (p) => p != "open",
})<{ open: boolean }>(({ theme, open }) => ({
  width: "40px",
  height: "24px",
  color: open ? theme.palette.neutral.main : "#19468d",
  marginLeft: open ? "16px" : "-56px",
  transition: "0.5s all",
}));

const SearchIcon = styled(SearchRoundedIcon, {
  shouldForwardProp: (p) => p != "open",
})<{
  open: boolean;
}>(({ theme, open }) => ({
  width: "40px",
  height: "24px",
  color: open ? theme.palette.neutral.main : "#19468d",
  marginLeft: open ? "-56px" : "0px",
  transition: "0.5s all",
}));

const SearchInput = styled("input", { shouldForwardProp: (p) => p != "open" })<{
  open: boolean;
}>(({ theme, open }) => ({
  position: "absolute",
  width: open ? "100%" : "42px",
  maxWidth: "320px",
  right: open ? "-3px" : 0,
  marginLeft: "4px",
  fontSize: "14px",
  padding: "14px 16px",
  border: `2px solid transparent`,
  height: open ? "auto" : "42px",
  borderRadius: "30px",
  zIndex: 1,
  background: theme.palette.neutral.main,
  transition: "0.5s ease-in-out",
}));

const SearchBtn = styled("button", { shouldForwardProp: (p) => p != "open" })<{
  open: boolean;
}>(({ theme, open }) => ({
  width: "40px",
  height: "40px",
  position: "relative",
  zIndex: 2,
  border: "none",
  borderRadius: "50%",

  color: open ? theme.palette.neutral.main : theme.palette.primary.main,
  background: open ? theme.palette.primary.main : theme.palette.neutral.main,
  overflow: "hidden",
  textDecoration: "none",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "0.5s all",
}));

const NavSearchInput = () => {
  const [open, setOpen] = React.useState(false);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.href);
    const value = searchParams.get("query");
    if (value) setSearchValue(value);
  }, []);

  const redirect = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key == "Enter") navigate(`/blog-search/?query=${searchValue}`);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  return (
    <>
      <SearchInput
        type="search"
        value={searchValue}
        onChange={handleChange}
        onKeyDown={redirect}
        open={open}
      />
      <SearchBtn open={open} onClick={() => setOpen(!open)}>
        <SearchIcon open={open} />
        {open && <CloseIcon open={open} />}
      </SearchBtn>
    </>
  );
};

export default NavSearchInput;
