import { Box, SxProps, Typography, styled } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FlexBox } from "../primitives/flex";
import * as Yup from "yup";
import { TextInput } from "../inputs/text-input";
import { CustomButton } from "../primitives/button";
import { FormikHelpers, useFormik } from "formik";
import { onReadyCaptcha } from "../../services/recaptcha";
import { sendZapierData, ZapierDataType } from "../../services/zapier";
import {
  loadRecaptchaScript,
  removeRecaptchaScript,
} from "../../utils/script-loader";

const Form = styled("form", {
  shouldForwardProp: (p) => p != "width" && p != "background",
})<{
  width?: string;
  background?: string;
}>(({ theme, width, background }) => ({
  width: width || "50%",
  display: "flex",
  justifyContent: "center",
  background: background || theme.palette.neutral.main,
  flexDirection: "column",
  alignItems: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ErrorText = styled(Typography)({
  fontSize: "16px",
  fontWeight: 400,
  color: "#dc3232",
  "&:first-letter": {
    textTransform: "uppercase",
  },
});

const Label = styled("label")(({ theme }) => ({
  fontSize: "14px",
  fontWeight: "600",
}));

const InputWrapper = styled(FlexBox, {
  shouldForwardProp: (p) => p != "inputGap",
})<{ inputGap: string }>(({ theme, inputGap }) => ({
  width: "100%",
  maxWidth: "490px",
  rowGap: inputGap || "30px",
  flexDirection: "column",
  paddingTop: "44px",
  paddingBottom: "100px",
  [theme.breakpoints.down(1200)]: {
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "inherit",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "20px",
    paddingRight: "20px",
  },
}));

const InputBox = styled(FlexBox)(({ theme }) => ({
  flexDirection: "column",
  rowGap: "4px",
}));

interface HelpCardFormValues {
  fullName: string;
  email: string;
  phoneNumber: string;
}

interface Props {
  formWidth?: string;
  background?: string;
  rootSx?: SxProps;
  inputGap?: string;
}

const validationSchema = Yup.object({
  fullName: Yup.string().required("*Mandatory"),
  phoneNumber: Yup.string().test(
    "is-number",
    "Please enter mobile number",
    (phoneNumber: string) => {
      const newPhone = phoneNumber as string;
      const phoneRegex =
        /\b[\+]?[(]?[0-9]{2,6}[)]?[-\s\.]?[-\s\/\.0-9]{7,15}\b/;
      return phoneRegex.test(newPhone);
    }
  ),
  email: Yup.string().email().required("*Mandatory"),
});

const HelpCardForm = (props: Props) => {
  const { formWidth, background, rootSx, inputGap } = props;
  const initialValues: HelpCardFormValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
  };

  const [submittedStatus, setSubmittedStatus] = React.useState<
    "pending" | "success" | "fail"
  >("pending");
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const [pageUrl, setPageUrl] = useState("");

  useEffect(() => {
    setPageUrl(window.location.href);
  }, []);

  const submitForm = async (
    values: HelpCardFormValues,
    formikHelpers: FormikHelpers<HelpCardFormValues>
  ) => {
    setIsSubmitting(true);

    onReadyCaptcha(
      async () => {
        // replace zapier function for sending details  IMPORTANT!!!!
        const response = await sendZapierData(
          ZapierDataType.helloHausContactForm,
          {
            ...values,
            pageUrl,
          }
        );
        if (response.status === "success") {
          setSubmittedStatus("success");
          formikHelpers.resetForm();
        } else setSubmittedStatus("fail");
        setIsSubmitting(false);
      },
      ({ error }) => {
        setIsSubmitting(false);
        console.log(error);
        window.alert("Captcha Verification Failed");
      }
    );
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: submitForm,
    validateOnMount: true,
  });

  React.useEffect(() => {
    const script = loadRecaptchaScript();
    return () => {
      removeRecaptchaScript(script);
    };
  }, []);
  const { handleSubmit, values, handleChange, handleBlur, touched, errors } =
    formik;
  const { email, fullName, phoneNumber } = values;
  return (
    <Form
      width={formWidth}
      background={background}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <InputWrapper sx={rootSx} inputGap={inputGap}>
        <InputBox>
          <Label>Full Name</Label>
          <TextInput
            value={fullName}
            name="fullName"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorText>{touched.fullName && errors.fullName}</ErrorText>
        </InputBox>
        <InputBox>
          <Label>Email</Label>
          <TextInput
            value={email}
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorText>{touched.email && errors.email}</ErrorText>
        </InputBox>
        <InputBox>
          <Label>Phone Number</Label>
          <TextInput
            value={phoneNumber}
            name="phoneNumber"
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <ErrorText>{touched.phoneNumber && errors.phoneNumber}</ErrorText>
        </InputBox>
        <CustomButton
          type="submit"
          disabled={isSubmitting}
          sx={{
            width: "106px",
            padding: "0",
            height: "40px",
            fontSize: "16px",
          }}
        >
          Submit
        </CustomButton>
        {submittedStatus != "pending" && (
          <Box
            color={submittedStatus === "success" ? "secondary.main" : "tomato"}
          >
            {submittedStatus === "success"
              ? "Thanks for getting in touch, one of our staff will be in contact soon!"
              : "Something went wrong please try later"}
          </Box>
        )}
      </InputWrapper>
    </Form>
  );
};

export default HelpCardForm;
