import React from "react";
import {
  styled,
  Box,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import HhContainer from "../hh-container";
import { FlexBox } from "../primitives/flex";
import FollowUs from "../follow-us";
import { ContactButton } from "../primitives/button";
import HHLogo from "../../svg/hhlogo";
import { graphql, useStaticQuery } from "gatsby";
import { FooterDataQueryQuery } from "../../../graphql-types";

const FooterBox = styled("footer")(({ theme }) => ({
  width: "100%",
}));

const FooterLogoBox = styled(Box)(({ theme }) => ({
  width: "30%",
  paddingBottom: "16px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Logo = styled(Typography)(({ theme }) => ({
  fontSize: "40px",
  color: theme.palette.secondary.main,
  paddingBottom: "30px",
}));

const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  color: theme.palette.neutral.main,
}));

const FootBox = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
    rowGap: "32px",
  },
}));

const FootBox2 = styled(FlexBox)(({ theme }) => ({
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  maxWidth: "200px",
  [theme.breakpoints.down("sm")]: {
    paddingBottom: "32px",
  },
}));

const LinkBox = styled(FlexBox)(({ theme }) => ({
  width: "100%",
  maxWidth: "300px",
  color: theme.palette.neutral.main,
  flexDirection: "column",
}));

const FooterGrid = styled(Grid)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(3,auto)",
  paddingTop: "10px",
  paddingBottom: "30px",
  width: "60%",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(2,auto)",
    gap: "20px",
    width: "100%",
  },
  [theme.breakpoints.down(400)]: {
    gridTemplateColumns: "1fr",
  },
}));

const FooterLinkBox = styled(FlexBox)(({ theme }) => ({
  width: "100%",
  columnGap: "16%",
  [theme.breakpoints.down("md")]: {
    columnGap: "inherit",
    rowGap: "30px",
    flexDirection: "column",
  },
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.neutral.main,
  fontWeight: "600",
  paddingBottom: "14px",
}));

const FootLink = styled("a")(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.neutral.main,
  paddingBottom: "14px",
  fontWeight: "400",
  cursor: "pointer",
  textDecoration: "none",
  ":hover": {
    color: theme.palette.secondary.main,
    transition: ".5s",
  },
}));

const footerData = [
  {
    title: "Our Service",
    options: [
      { name: "How we work", btnText: "" },
      { name: "Portfolio builder", btnText: "" },
      { name: "Blog", btnText: "" },
    ],
  },
  {
    title: "Company",
    options: [
      { name: "About Us", btnText: "" },
      { name: "Reviews", btnText: "" },
      { name: "Common Questions", btnText: "" },
    ],
  },
  {
    title: "Contact",
    options: [
      { name: "1300 391 531", btnText: "Call us" },
      {
        name: "support@hellohaus.co",
        btnText: "Email us",
      },
    ],
  },
];

const Footer = () => {
  const theme = useTheme();

  const footerLinkData = useStaticQuery<FooterDataQueryQuery>(graphql`
    query FooterDataQuery {
      CraftGQL {
        helloHausFooterNavigationEntries(site: "helloHaus") {
          ... on CraftGQL_helloHausFooterNavigation_default_Entry {
            id
            title
            urlsWithLabels {
              ... on CraftGQL_urlsWithLabels_urlEntry_BlockType {
                id
                entry {
                  text
                  url
                }
              }
            }
          }
        }
      }
    }
  `);

  const { CraftGQL } = footerLinkData;

  const footnavEntries = CraftGQL.helloHausFooterNavigationEntries;

  console.log("Footer Data is ", CraftGQL);

  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.up("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const showInDesktop = (tpIndex: number, btIndex: number, footOptions: any) =>
    lg &&
    tpIndex === footerData.length - 1 &&
    btIndex === footOptions.length - 1;

  return (
    <FooterBox>
      <HhContainer sectionBgcolor="primary">
        <FooterLinkBox>
          <FooterLogoBox>
            <HHLogo />
            <FooterText>A smarter way to buy property!</FooterText>
          </FooterLogoBox>
          <FooterGrid>
            {footnavEntries.reverse().map((foot, tpIndex) => {
              return (
                <LinkBox>
                  <Title>{foot.title}</Title>
                  {foot.urlsWithLabels.map((opt, btIndex) => (
                    <>
                      {foot.title === "Contact" && sm ? (
                        <ContactButton
                          href={opt.entry.url}
                          showInMobile
                          sx={{
                            marginBottom: "10px",
                            maxWidth: "90px",
                            textAlign: "center",
                          }}
                        >
                          {btIndex === 0 ? "Call us" : "Email us"}
                        </ContactButton>
                      ) : (
                        <FootLink href={opt.entry.url}>
                          {opt.entry.text}
                        </FootLink>
                      )}
                      {showInDesktop(tpIndex, btIndex, foot.urlsWithLabels) && (
                        <FollowUs />
                      )}
                    </>
                  ))}
                </LinkBox>
              );
            })}
            {md && <FollowUs />}
          </FooterGrid>
        </FooterLinkBox>
      </HhContainer>
      <HhContainer customBg={theme.palette.primary.dark}>
        <FootBox>
          <FootLink>© 2023 All Rights Reserved</FootLink>
        </FootBox>
      </HhContainer>
    </FooterBox>
  );
};

export default Footer;
