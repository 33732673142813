import * as React from "react";
import type { GatsbyBrowser } from "gatsby";
import "firebase/functions";
import Layout from "./src/components/layout";

export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
}: {
  element: any;
}) => {
  return (
    <React.Fragment>
      <Layout>
        <main>{element}</main>
      </Layout>
    </React.Fragment>
  );
};
