import React from "react";
import HhContainer from "../components/hh-container";
import { styled, Box, Typography } from "@mui/material";
import { FlexBox } from "../components/primitives/flex";
import HelpCardForm from "../components/forms/help-card-form";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookRoundedIcon from "@mui/icons-material/FacebookRounded";
import YouTubeIcon from "@mui/icons-material/YouTube";

export const mediaData = [
  {
    icon: <InstagramIcon sx={{ fontSize: "20px", color: "#ffff" }} />,
    bgColor: "#cd1d3e",
    linkUrl: "https://www.instagram.com/hellohausco/",
  },
  {
    icon: <FacebookRoundedIcon sx={{ fontSize: "20px", color: "#ffff" }} />,
    bgColor: "#0b5ec9",
    linkUrl: "https://www.facebook.com/hellohausco",
  },
  {
    icon: <YouTubeIcon sx={{ fontSize: "20px", color: "#ffff" }} />,
    bgColor: "#a41a19",
    linkUrl: "https://www.youtube.com/channel/UCZNosO9bGqRcpKRUrPRkgAQ",
  },
];

const CardWrapper = styled(FlexBox)(({ theme }) => ({
  overflow: "hidden",
  borderRadius: "10px",
  position: "relative",
  boxShadow:
    "rgba(10,28,56,.6) 0 30px 50px -20px, rgba(25,70,141,.3) 0 20px 20px -20px",
  width: "100%",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column-reverse",
  },
}));

const OrCircle = styled(FlexBox)(({ theme }) => ({
  width: "42px",
  height: "42px",
  justifyContent: "center",
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "50%",
  transform: "translate(-50%,-50%)",
  alignItems: "center",
  background: theme.palette.secondary.main,
  fontSize: "20px",
  fontWeight: "600",
  color: theme.palette.primary.main,
  boxShadow: " 0 2px 4px rgba(10,10,10,.4)",
}));

export const SocialMedia = styled(FlexBox, {
  shouldForwardProp: (p) => p != "bgColor",
})<{ bgColor: string }>(({ theme, bgColor }) => ({
  color: theme.palette.neutral.main,
  background: bgColor,
  width: "42px",
  height: "42px",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "14px",
  cursor: "pointer",
  marginRight: "6px",
  ":hover": {
    transform: "scale(1.1)",
    transition: "transform 0.3s ease-in-out",
  },
}));

const GetInTouchBox = styled(FlexBox)(({ theme }) => ({
  width: "50%",
  background:
    "linear-gradient(90deg,#0a1c38,rgba(10,28,56,0)),url(	https://hellohaus.co/get-help-now/_send-us-a-message/hh-p1-hero-05.jpg)",
  backgroundSize: "cover",
  backgroundPosition: "center",
  justifyContent: "center",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const ContentText = styled(Typography)(({ theme }) => ({
  fontSize: "48px",
  fontWeight: "600",
  color: theme.palette.neutral.main,
  "& p": {
    margin: 0,
  },
}));

const LinkBox = styled("a")(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  color: theme.palette.neutral.main,
  textDecoration: "none",
  cursor: "pointer",
  ":hover": {
    color: theme.palette.secondary.main,
    transition: "0.5s all",
  },
}));

const ContentBox = styled(FlexBox)(({ theme }) => ({
  width: "100%",
  maxWidth: "490px",
  flexDirection: "column",
  rowGap: "20px",
  paddingTop: "44px",
  paddingBottom: "80px",
  [theme.breakpoints.down(1200)]: {
    paddingLeft: "40px",
  },
  [theme.breakpoints.down("md")]: {
    maxWidth: "inherit",
    paddingBottom: "124px",
  },
  [theme.breakpoints.down("sm")]: {
    paddingLeft: "20px",
  },
}));

const SocialLink = styled("a")(({ theme }) => ({
  textDecoration: "none",
  paddingTop: "5px",
  cursor: "pointer",
}));

const HelpCardSection = () => {
  return (
    <HhContainer
      customBg="#f1f1f1"
      rootSx={{ paddingY: { md: "120px", xs: "70px" } }}
    >
      <CardWrapper>
        <OrCircle>OR</OrCircle>
        <HelpCardForm />
        <GetInTouchBox>
          <ContentBox>
            <ContentText variant="h3">Get in Touch</ContentText>
            <LinkBox href="tel:1300931521">
              <PhoneIcon />
              <Typography fontSize="20px" pl={1}>
                1300 931 521
              </Typography>
            </LinkBox>
            <LinkBox href="mailto:support@hellohaus.co">
              <EmailRoundedIcon />
              <Typography fontSize="20px" pl={1}>
                support@hellohaus.co
              </Typography>
            </LinkBox>
            <ContentText variant="h3">Follow Us</ContentText>
            <FlexBox>
              {mediaData.map((med) => (
                <SocialLink href={med.linkUrl} target="_blank">
                  <SocialMedia bgColor={med.bgColor}>{med.icon}</SocialMedia>
                </SocialLink>
              ))}
            </FlexBox>
          </ContentBox>
        </GetInTouchBox>
      </CardWrapper>
    </HhContainer>
  );
};

export default HelpCardSection;
