import React from "react";
import { FlexBox } from "./primitives/flex";
import { Typography, styled } from "@mui/material";
import { NavButton } from "./primitives/button";
import HHLogo from "../svg/hhlogo";

interface LogoBoxProps {
  showHomeText: boolean;
}

const Logo = styled(Typography)(({ theme }) => ({
  fontSize: "30px",
  fontWeight: "700",
  color: theme.palette.primary.light,
  paddingRight: "10px",
}));

const LogoText = styled(Typography)(({ theme }) => ({
  fontSize: "20px",
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const LogoBox = (props: LogoBoxProps) => {
  const { showHomeText } = props;
  return (
    <FlexBox alignItems="center">
      <a style={{ textDecoration: "none" }} href="/">
        <HHLogo />
      </a>
    </FlexBox>
  );
};

export default LogoBox;
